import PropTypes from "prop-types"
import React from "react"

const TitleHeader = ({ title, subtitle }) => (
  <div className="contentContainer pt-6">
    <span className="font-light -mb-1 italic opacity-80 block text-violet-600">{subtitle}</span>
    <span className="font-medium text-xl text-violet-600">{title}</span>
</div>
)

TitleHeader.propTypes = {
  title: PropTypes.string,
}

TitleHeader.defaultProps = {
  title: ``,
}

export default TitleHeader
