import React from "react"

import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TitleHeader from "../components/titleHeader"

const RecipesGrid = ({data}) => {
  const recipes = data.allContentfulRecipe.edges;

  return (
  <Layout>
    <SEO title="All Recipes" />
    <TitleHeader title="Grid Recipes" />
    <div className="contentContainer">
      <div className="p-4">
      <div className="grid grid-cols-2" >
      { recipes
            .filter(post => post.node.title.length > 0)
            .sort()
            .map(({ node: recipe }, index) => (
              <div key={recipe.id}>
                <div className="px-2 py-4">
                  <Link to={`/${recipe.slug}`}>
                    <h2 className="text-base font-medium hover:text-violet-700 text-gray-800 dark:text-white">{recipe.title}</h2>
                    <h3 className="-mt-1 text-sm italic opacity-70 dark:text-white">{recipe.author}</h3>
                  </Link>
                </div>
                <hr />
              </div>
            ))}
      </div>
      
      </div>
    </div>
  </Layout>
  )
}

export default RecipesGrid

export const pageQuery = graphql`
  query RecipesGridPageQuery {
    allContentfulRecipe {
      edges {
        node {
          id
          title
          slug
          tags
          author 
          images {
            file {
              url
            }
          }
          addedDate(formatString: "MM-DD-YYYY")
        }
      }
    }
  }
`